<template>
  <div>
    <!-- Header -->
    <div class="relative bg-gray-900 h-screen">
      <!-- Decorative image and overlay -->
      <div aria-hidden="true" class="absolute inset-0 overflow-hidden">
        <img
          :src="contact.Header_Image"
          alt=""
          class="w-full h-full object-center object-cover"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        />
        <div aria-hidden="true" class="absolute inset-0 bg-black opacity-60" />
      </div>

      <div
        class="relative max-w-3xl mx-auto py-40 px-6 flex flex-col items-center text-center sm:py-30 sm:py-40 lg:px-0"
      >
        <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
          <img class="h-10 mx-auto" src="@/assets/5.png" alt="" />
          <h1
            class="mt-5 text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"
          >
            <span class="block text-white">Contact Us.</span>
          </h1>
        </div>
      </div>
    </div>
    <!-- Header -->
    <!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
    <div class="relative bg-white">
      <div class="lg:absolute lg:inset-0">
        <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 shadow-md">
          <img
            class="h-56 w-full object-cover lg:absolute lg:h-full"
            :src="contact.Side_Image"
          />
        </div>
      </div>
      <div
        class="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2"
      >
        <div class="lg:pr-8">
          <div class="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2
              class="text-3xl font-extrabold text-black sm:text-4xl text-left"
            >
              Contact Us.
            </h2>
            <p class="mt-4 text-lg text-black sm:mt-3">
              {{ contact.Contact_Paragraph }}
            </p>
            <div class="flex flex-col pt-4 items-left">
              <div class="bg-white">
                <Button />
              </div>
            </div>
            <h2 class="mt-5 text-2xl font-extrabold tracking-tight sm:text-2xl">
              Opening Hours.
            </h2>
            <div>
              <p class="mt-4 text-lg text-black sm:mt-3">
                {{ contact.Opening_Times_1 }}
              </p>
              <p class="mt-4 text-lg text-black sm:mt-3">
                {{ contact.Opening_Times_2 }}
              </p>
              <p class="mt-4 text-lg text-black sm:mt-3">
                {{ contact.Opening_Times_3 }}
              </p>
            </div>

            <h2 class="mt-5 text-2xl font-extrabold tracking-tight sm:text-2xl">
              Contact Details.
            </h2>
            <p class="mt-4 text-lg text-black sm:mt-3">
              Address: One Squared, Unit 8 Bayside Shopping Center, Dublin 13,
              D13 C2F6, Ireland.
            </p>
            <p class="mt-4 text-lg text-black sm:mt-3">
              Email: contact@onesquared.ie
            </p>
            <p class="mt-4 text-lg text-black sm:mt-3">
              Phone Number: 01 839 3226
            </p>

            <div class="flex space-x-6 mt-4 sm:mt-3">
              <a
                v-for="item in navigation.social"
                :key="item.name"
                :href="item.href"
                :aria-label="item.aria"
                target="_blank"
                class="text-pb hover:text-sg"
              >
                <span class="sr-only">{{ item.name }}</span>
                <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MAP STARTS -->

    <div class="relative bg-white shadow-md">
      <div class="max-w mx-auto">
        <iframe
          allowfullscreen
          aria-hidden="false"
          frameborder="0"
          height="500"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8508.250138126463!2d-6.138689845954273!3d53.39181156401936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486705c1f6681825%3A0xdb8809d7e00cc14c!2sOne%20Squared%20Bayside!5e0!3m2!1sen!2sth!4v1657611876153!5m2!1sen!2sth"
          style="border: 0; display: block"
          tabindex="0"
          width="100%"
        />
      </div>
    </div>
    <!-- MAP ENDS -->
  </div>
</template>

<script>
import axios from "axios";
import Button from "../components/opentable/Button.vue";

export default {
  data() {
    return {
      contact: {
        Header_Image: "",
        Contact_Paragraph: "",
        Side_Image: "",
        Opening_Times_1: "",
        Opening_Times_2: "",
        Opening_Times_3: "",
      },
      components: {
        Button,
      },
    };
  },

  methods: {
    getContactPage() {
      let __this = this;
      axios
        .get(
          "https://api.storyblok.com/v2/cdn/stories/contact?cv=1657959327&token=e90vmTfGJpbIf0qqX2mihwtt&version=published"
        )
        .then((response) => {
          let content = response.data["story"]["content"];
          __this.contact = {
            Header_Image: content["Header_Image"]["filename"],
            Side_Image: content["Side_Image"]["filename"],
            Contact_Paragraph: content["Contact_Paragraph"],
            Opening_Times_1: content["Opening_Times_1"],
            Opening_Times_2: content["Opening_Times_2"],
            Opening_Times_3: content["Opening_Times_3"],
          };
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
    this.getContactPage();
  },
};
</script>

<script setup>
import { defineComponent, h } from "vue";

const navigation = {
  social: [
    {
      name: "Facebook",
      aria: "Facebook",
      href: "https://facebook.com/OneSquaredBayside/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Instagram",
      aria: "Instagram",
      href: "https://www.instagram.com/onesquaredbayside/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
  ],
};
</script>
